<template>
  <div class="dis_flex flex_dir_col ali_it_cen">
    <h1 class="m_t_16">404</h1>
    <h3 class="m_t_16">请指定您要前往地址</h3>

    <!--  -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    
  },
  mounted() {},
  methods: {},
};
</script>
