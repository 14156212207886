// HTTP 请求
import qs from 'qs';
import axios from 'axios';
import VConsole from 'vconsole';
import { Toast } from 'vant';
import Router from '../router/index'

const CancelToken = axios.CancelToken;

// 显示vconsole
if (['development', 'sit', 'uat'].includes(process.env.NODE_ENV)) {
  new VConsole();
}

// Axios 配置
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
axios.defaults.timeout = 1000 * 60; // 超时时间
axios.defaults.withCredentials = false; // 携带客户端验证身份
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

const _token = localStorage.getItem('__token');
if (_token) {
  axios.defaults.headers['Authorization'] = _token;
}

// 正在执行的请求
const pending = [];

/**
 * URL 参数混入
 * @param {String} url URL
 * @param {Object} params 参数
 * @param {boolean} remove 匹配成功时，是否从参数中移除
 */
function _mixin(url, params, remove = true) {
  if (params && '[object Object]' === Object.prototype.toString.call(params)) {
    for (const key in params) {
      const reg = new RegExp(`{${key}}`, 'g');
      if (reg.test(url)) {
        url = url.replace(reg, params[key]);
        if (remove) {
          delete params[key];
        }
      }
    }
  }
  return url;
}

// 检查执行的请求是否已经存在，已存在时，取消上次请求
function abortExisit(instance) {
  for (const key in pending) {
    if (pending[key].path === instance.url + '::' + instance.method) {
      pending[key].cancel.cancel();
      pending.splice(key, 1);
    }
  }
}

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (localStorage.userInfo) {
      const u = JSON.parse(localStorage.userInfo);
      config.headers['Authorization'] = `${u.token_type} ${u.access_token}`;
    }

    if (!['/oauth/token', '/auth/sms'].includes(config.url)) {
      const _token = localStorage.getItem('__token');
      if (_token) {
        config.headers['Authorization'] = _token;
      }
    }

    // 取消同一请求并创建新的取消标识
    abortExisit(config);
    const source = CancelToken.source();
    config.cancelToken = source.token;

    pending.push({
      path: config.url + '::' + config.method,
      cancel: source,
    });

    return config;
  },
  (error) => {
    // console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    const data = res.data;
    return data;
  },
  (error) => {
    if (error.__CANCEL__) return true;

    if (!error.response) return Promise.reject('网络错误，请稍后再试！');

    if (403 === error.response.status) {
      for (let key in localStorage) {
        if (/(^__)/.test(key)) {
          localStorage.removeItem(key);
        }
      }

      Toast('登录失效');
      localStorage.removeItem('userInfo');
      Router.replace('/login');
      return Promise.reject('当前登录信息已失效，请重新登录');
    }

    if (error.response && 404 === error.response.status) {
      return Promise.reject(`${error.request.responseURL} 接口不存在`);
    }

    if (/timeout/.test(error)) return Promise.reject('接口响应超时，请重试！');

    return Promise.reject(
      error.response.data.error_description ||
      error.response.data.msg ||
      error.response.toString() ||
      error.toString()
    );
  }
);

/**
 * 发送请求
 * @param {*} config
 */
const request = function (config) {
  if (config.url) {
    if (config.data && config.data instanceof Object) {
      config.url = _mixin(config.url, config.data);
    }
    if (config.params && config.params instanceof Object) {
      config.url = _mixin(config.url, config.params);
    }
  }

  return axios(config);
};

// 封装 GET 方法
export const get = function (url, params = {}, options = {}) {
  return request({
    method: 'GET',
    url,
    params,
    options,
  });
};

// 封装 POST 方法
export const post = function (url, data = {}, options = {}) {
  if (data && '[object Object]' === Object.prototype.toString.call(data)) {
    // 分页信息需要放在 URL 中
    try {
      // 取出分页相关字段
      const pageParams = {};

      // 滤除分页相关的字段
      for (const key in data) {
        if (['current', 'size'].includes(key)) {
          let value = data[key];

          // 当前页码从第一页开始
          if ('current' === key) {
            value = Math.max(1, +value || 0);
          }

          // 必须设置每页显示条数
          if ('size' === key) {
            value = +value || 0;
            value = 0 === value ? 20 : Math.max(1, value);
          }

          pageParams[key] = value;
          delete data[key];
        }
      }

      // 混合 URL
      if (JSON.stringify(pageParams) !== '{}') {
        if (url.includes('?')) {
          url = `${url}&${qs.stringify(pageParams)}`;
        } else {
          url = `${url}?${qs.stringify(pageParams)}`;
        }
      }
    } catch (error) {
      Toast(error.toString());
    }

    for (const key in data) {
      if (null === data[key] || '' === data[key]) {
        delete data[key];
      }
    }
  }

  return request({
    method: 'POST',
    url,
    data,
    ...options,
  });
};

// 封装 PUT 方法
export const put = function (url, data = {}, options = {}) {
  return request({
    method: 'PUT',
    url,
    data,
    ...options,
  });
};

// 封装 PATCH 方法
export const patch = function (url, data = {}, options = {}) {
  return request({
    method: 'PATCH',
    url,
    data,
    ...options,
  });
};

// 封装 DELETE 方法
export const del = function (url, data = {}, options = {}) {
  return request({
    method: 'DELETE',
    url,
    data,
    ...options,
  });
};

export default {
  get,
  post,
  put,
  patch,
  axios,
  request,
  delete: del,
};
